var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(! _vm.isModal)?_c('div',{staticClass:"level is-variable bd-klmn-columns is-1 is-marginless"},[_c('div',{staticClass:"level-left"},[_c('b-button',{staticClass:"filter-button is-hidden-mobile",attrs:{"size":"is-circle","icon-left":"filter-remove"},on:{"click":_vm.clearFilter}}),_c('div',{staticClass:"filter-tags"},_vm._l((_vm.filters),function(value,index){return _c('b-tag',{key:index,attrs:{"type":"is-purble","closable":""},on:{"close":function($event){return _vm.removeFilter(value)}}},[_vm._v(" "+_vm._s(_vm.$t('filter.' + value))+" ")])}),1)],1),_c('div',{staticClass:"level-right"},[_vm._t("header-right")],2)]):_vm._e(),_c('b-table',{staticClass:"no-wrap",attrs:{"data":_vm.data,"loading":_vm.isLoading,"current-page":_vm.page,"total":_vm.total,"per-page":_vm.perPage,"default-sort-direction":_vm.defaultSortOrder,"default-sort":[_vm.sortField, _vm.sortOrder],"striped":"","sticky-header":"","paginated":"","hoverable":"","backend-sorting":"","backend-pagination":"","aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page"},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"page-change":_vm.onPageChange,"sort":_vm.onSort}},[_c('b-table-column',{attrs:{"custom-key":"actions","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-button',{staticClass:"button-purble btn-details",attrs:{"outlined":"","size":"is-small","tag":"router-link","to":{ name: 'addresses.edit', params: { id: props.row.id }},"icon-left":"arrow-right-bold"}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('filter.id'),"field":"id","number":"","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{staticClass:"id-search search-dropdown",attrs:{"aria-role":"menu","close-on-click":false,"trap-focus":""}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"aria-role":"menu-item","focusable":false}},[_c('b-input',{attrs:{"placeholder":_vm.$t('idSearch'),"size":"is-small","autocomplete":"off","type":"search"},model:{value:(_vm.queryData.id),callback:function ($$v) {_vm.$set(_vm.queryData, "id", $$v)},expression:"queryData.id"}})],1)],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_vm._v(" #"+_vm._s(props.row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('filter.address_name'),"field":"name","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{staticClass:"search-dropdown",attrs:{"aria-role":"menu","close-on-click":false}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"aria-role":"menu-item","focusable":false}},[_c('b-input',{attrs:{"placeholder":_vm.$t('addressNameSearch'),"size":"is-small","autocomplete":"off","type":"search"},model:{value:(_vm.queryData.name),callback:function ($$v) {_vm.$set(_vm.queryData, "name", $$v)},expression:"queryData.name"}})],1)],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":props.row.name,"active":props.row.name.length > 20,"type":"is-dark","position":"is-bottom"}},[_vm._v(" "+_vm._s(_vm._f("limitShow")(props.row.name))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('filter.address'),"field":"address","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{staticClass:"search-dropdown",attrs:{"aria-role":"list","close-on-click":false}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"aria-role":"menu-item"}},[_c('b-input',{attrs:{"placeholder":_vm.$t('addressSearch'),"size":"is-small","autocomplete":"off","type":"search"},model:{value:(_vm.queryData.address),callback:function ($$v) {_vm.$set(_vm.queryData, "address", $$v)},expression:"queryData.address"}})],1)],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":props.row.address,"active":props.row.address.length > 40,"type":"is-dark","position":"is-bottom"}},[_vm._v(" "+_vm._s(_vm._f("limitShow")(props.row.address,40))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('filter.description')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":props.row.name,"active":props.row.name.length > 20,"type":"is-dark","position":"is-bottom"}},[_vm._v(" "+_vm._s(_vm._f("limitShow")(props.row.description))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('filter.status'),"field":"is_active"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{attrs:{"aria-role":"list","close-on-click":false},model:{value:(_vm.queryData.is_active),callback:function ($$v) {_vm.$set(_vm.queryData, "is_active", $$v)},expression:"queryData.is_active"}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"value":true,"aria-role":"listitem"}},[_c('span',[_vm._v(_vm._s(_vm.$t('itemStatus.true')))])]),_c('b-dropdown-item',{attrs:{"value":false,"aria-role":"listitem"}},[_c('span',[_vm._v(_vm._s(_vm.$t('itemStatus.false')))])])],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"tag",class:[props.row.is_active ? 'wdriver' : 'cancel']},[_vm._v(" "+_vm._s(_vm.$t(("itemStatus." + (props.row.is_active))))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('filter.created_at'),"field":"created_at","sortable":"","date":"","centered":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{staticClass:"date-search search-dropdown",attrs:{"aria-role":"menu","close-on-click":false}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"custom":"","paddingless":"","aria-role":"menu-item","focusable":false}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t('dateSearch'),"first-day-of-week":1,"month-names":_vm.$t('dateTimePicker.monthNames'),"day-names":_vm.$t('dateTimePicker.dayNames'),"icon":"calendar-today","range":"","inline":""},model:{value:(_vm.queryData.created_at),callback:function ($$v) {_vm.$set(_vm.queryData, "created_at", $$v)},expression:"queryData.created_at"}},[_c('div',{staticClass:"button is-danger",on:{"click":function($event){_vm.queryData.created_at = []}}},[_vm._v(" "+_vm._s(_vm.$t('clear'))+" ")])])],1)],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_c('small',{staticClass:"tag is-abbr-like",attrs:{"title":props.row.created_at}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.created_at))+" ")])]}}])}),_c('template',{slot:"bottom-left"},[(_vm.total > 0)?_c('div',{staticClass:"has-text-left subtitle help"},[_vm._v(" "+_vm._s(_vm.$t('paginationShowing', [_vm.total.toLocaleString(), ((_vm.page * _vm.perPage) - _vm.perPage) + 1, ((_vm.page * _vm.perPage) > _vm.total ? _vm.total : (_vm.page * _vm.perPage)) ]))+" ")]):_vm._e()]),_c('section',{staticClass:"section",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[(_vm.isLoading)?[_c('p',[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-large"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t('fetching'))+"...")])]:[_c('p',[_c('b-icon',{attrs:{"icon":"table","size":"is-large"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t('nodata')))])]],2)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }